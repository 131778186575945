<template>
  <div class="d-flex align-center justify-end">
    <v-chip-group column>
      <v-chip
        v-for="studentGroup in student.studentGroups"
        :key="studentGroup"
        outlined
        @click.stop="remove(studentGroup)"
        >{{ studentGroup }}</v-chip
      >
    </v-chip-group>

    <v-dialog v-model="dialog" width="500" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-if="canEdit" v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-system-bar window>
          Gruppe hinzufügen<v-spacer />
          <v-btn @click="dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-system-bar>
        <v-list subheader>
          <v-subheader>vorhandene Gruppen wählen</v-subheader>
          <v-list-item>
            <v-chip-group column
              ><v-chip
                v-for="group in studentGroups"
                outlined
                @click="add(group)"
                :key="group"
                color="primary"
                :disabled="student.studentGroups.includes(group)"
                >{{ group }}</v-chip
              ></v-chip-group
            >
          </v-list-item>
          <v-divider />
          <v-subheader>neue Gruppe erstellen</v-subheader>
          <v-list-item>
            <v-text-field
              placeholder="Name der Gruppe"
              singleLine
              hideDetails
              v-model="newGroup"
              ><template v-slot:append-outer
                ><v-btn
                  text
                  color="primary"
                  :disabled="!newGroup"
                  @click="add(newGroup)"
                  >hinzufügen
                </v-btn></template
              >
            </v-text-field>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">schliessen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { personName } from "common/utils/people.js";

export default defineComponent({
  name: "StudentGroupChipos",
  props: ["course", "student", "studentGroups"],
  data() {
    return {
      dialog: false,
      newGroup: "",
    };
  },
  computed: {
    canEdit() {
      return (
        this.course && this.course.teachers.some((el) => this.$_isPerson(el))
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.studentGroups = await this.apiGet({
        resource: "course/coursegroup",
        id: this.course.id,
      });
      this.loading = false;
    },
    async add(studentGroup) {
      if (this.canEdit) {
        if (
          await this.$root.confirm({
            color: "success",
            icon: "mdi-plus",
            message: `Soll <i>${personName(
              this.student
            )}</i> in die Gruppe «${studentGroup}» aufgenommen werden?`,
          })
        ) {
          await this.apiPatch({
            resource: "course/coursegroup",
            id: this.course.id,
            key: "add",
            value: {
              student: this.student.id,
              group: studentGroup,
            },
          });
          this.$emit("updated");
          this.dialog = false;
        }
      }
    },
    async remove(studentGroup) {
      if (this.canEdit) {
        if (
          await this.$root.confirm({
            color: "danger",
            icon: "mdi-trash-can",
            message: `Soll <i>${personName(
              this.student
            )}</i> aus der Gruppe «${studentGroup}» entfernt werden?`,
          })
        ) {
          await this.apiPatch({
            resource: "course/coursegroup",
            id: this.course.id,
            key: "remove",
            value: {
              student: this.student.id,
              group: studentGroup,
            },
          });
          this.$emit("updated");
          this.dialog = false;
        }
      }
    },
  },
});
</script>
